import { BASE_API_URL } from "src/utils/app-constants";

export const PRODUCT_URL = `${BASE_API_URL}product`;
export const POPULAR_BILLERS_URL = `${BASE_API_URL}biller`;
export const BANKS_URL = `${BASE_API_URL}bank`;
export const USER_URL = `${BASE_API_URL}user`;
export const ROLE_URL = `${BASE_API_URL}role`;
export const AUTH_URL = `${BASE_API_URL}auth/login`;
export const VERIFICATION_URL = `${BASE_API_URL}auth/otp/verify`;
export const GLOBAL_SETTINGS_URL = `${BASE_API_URL}global-setting`;
export const DEAL_DOCUMENT_URL = `${BASE_API_URL}product-document`;
export const DEAL_URL = `${BASE_API_URL}deal`;
export const DEAL_TARRIF_URL = `${BASE_API_URL}deal-tariff`;
export const PERIOD_URL = `${BASE_API_URL}invite-friend-period`;
export const COMMISSION_RULES_URL = `${BASE_API_URL}invite-friend-commission-rule`;
export const INVITE_FRIENDS_SETTINGS_URL = `${BASE_API_URL}invite-friend-settings`;
export const PAYOUTS_URL = `${BASE_API_URL}invite-friend-payout`;
export const REFERRALS_URL = `${BASE_API_URL}invite-friend-referral`;
export const STATS_URL = `${BASE_API_URL}invite-friend-stats`;
export const TIER_URL = `${BASE_API_URL}invite-friend-commission-rule-tier`;
export const MERCHANT_URL = `${BASE_API_URL}merchant`;
export const NOTIFICATIONS_URL = `${BASE_API_URL}notification`;
export const CVM_GROUP_URL = `${BASE_API_URL}cvm-group`;
export const CVM_GROUP_MEMBER_URL = `${BASE_API_URL}cvm-group-member`;
export const CVM_NOTIFICATION_URL = `${BASE_API_URL}cvm-notification`;
export const CVM_NOTIFICATION_TARGET_URL = `${BASE_API_URL}cvm-notification-target`;
export const TRANSACTIONS_URL = `${BASE_API_URL}transaction`;
export const TRANSACTIONS_STATS_URL = `${BASE_API_URL}transaction/stats`;
export const EVENTS_URL = `${BASE_API_URL}event`;
