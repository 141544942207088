import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import axios from "axios";
import { AUTH_URL, VERIFICATION_URL } from "src/api/urls";
import { useHistory, useLocation } from "react-router";
import { setToken } from "src/utils/LocalStorage";
import { toast } from "react-toastify";
import { useAdmin } from "src/api/hooks/useAdmin";

const Verification = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const location = useLocation();
  const history = useHistory();
  const { message } = location?.state;
  const isAdmin = useAdmin();

  console.log(isAdmin, "admin");

  const user = JSON.parse(localStorage.getItem("user"));
  const handleOtpVerification = async (data) => {
    const payload = {
      ...data,
      phone_number: user.phone_number,
    };
    const { data: response } = await axios.post(VERIFICATION_URL, payload);
    return response;
  };

  const { mutate, isLoading } = useMutation(handleOtpVerification, {
    onSuccess: (data) => {
      toast.success("Otp code verified successfully");
      setToken(data.access_token);
      history.push("/");
    },
    onError: (data) => {
      toast.error(data.message);
    },
    onSettled: () => {},
  });

  const onSubmit = handleSubmit((data) => {
    mutate(data);
  });

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={onSubmit}>
                    <h1>Code Verification</h1>
                    <p className="text-muted">{message}</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <input
                        className="form-control"
                        type="otp"
                        placeholder="21345"
                        autoComplete="otp"
                        name="otp"
                        {...register("otp", {
                          required: "Please enter the otp code",
                        })}
                      />
                    </CInputGroup>
                    <h6 className="required">{errors.otp?.message}</h6>

                    <CRow>
                      <CCol xs="9">
                        <CButton color="primary" type="submit" className="px-4">
                          VERIFY OTP
                        </CButton>
                      </CCol>
                      <CCol xs="3">
                        <CButton
                          color="primary"
                          variant="outline"
                          type="button"
                          onClick={() => history.push("/login")}
                        >
                          Back to login
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Verification;
