let DEV_BASE_URL = "http://localhost";
let STAGING_BASE_URL = "http://admin.integ.dfs.laheri.co.ke";
let PRODUCTION_BASE_URL = "https://admin.prod.dfs.laheri.co.ke";
let PROD_BASE_URL = "https://admin.dfs.telkom.co.ke";

let PRODUCTION_BASE_API_URL = "https://api.prod.dfs.telkom.co.ke/api/v1/";
let STAGING_BASE_API_URL = "https://api.integ.dfs.laheri.co.ke/api/v1/";

let BASE_URL = "";
let BASE_API_URL = "";
let QUESTIONS_KEY = "__questions__";
let PROFILE_KEY = "__profile__";
let ANSWERS_KEY = "__answers__";
let LOCALE_KEY = "__lang__";
let STAGE_TYPE_KEY = "__stage__";
let PREVIOUS_STAGE_KEY = "__previous-stage__";
let FLASH_MESSAGE_KEY = "__flash-message__";

let location = window && window.location;

const hostname = `${location.protocol}//${location.hostname}`;

switch (hostname) {
  case STAGING_BASE_URL:
    BASE_URL = STAGING_BASE_URL;
    BASE_API_URL = STAGING_BASE_API_URL;
    // "Staging";
    break;

  case PRODUCTION_BASE_URL:
    BASE_URL = PRODUCTION_BASE_URL;
    BASE_API_URL = PRODUCTION_BASE_API_URL;
    // "Production";
    break;

  case PROD_BASE_URL:
    BASE_URL = PROD_BASE_URL;
    BASE_API_URL = PRODUCTION_BASE_API_URL;
    // "Production";
    break;

  default:
    BASE_URL = `${DEV_BASE_URL}:${location.port}`;
    BASE_API_URL = STAGING_BASE_API_URL;
    // "Dev";
    break;
}

module.exports = {
  BASE_URL,
  BASE_API_URL,
  QUESTIONS_KEY,
  PROFILE_KEY,
  ANSWERS_KEY,
  LOCALE_KEY,
  STAGE_TYPE_KEY,
  PREVIOUS_STAGE_KEY,
  FLASH_MESSAGE_KEY,
};
