import { useEffect, useState } from "react";

export const useAdmin = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (user.role.name === "ADMIN" || user.role.name === "FINANCE") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [user]);
  return isAdmin;
};
