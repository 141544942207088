import React, { createContext, useMemo, useState } from "react";
import Axios from "axios";
import { getToken } from "src/utils/LocalStorage";
import { useHistory } from "react-router-dom";
// import { useNavigation } from '@react-navigation/native';
// import {getAsync} from '../../utils';
//@ts-ignore
export const AxiosContext = createContext(undefined);

export default function AxiosProvider({ children }) {
  // const navigation = useNavigation();
  const [tokenInit, setTokenInit] = useState("");
  const history = useHistory();
  const axios = useMemo(() => {
    const axios = Axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });

    axios.interceptors.request.use(async (config) => {
      // Read token for anywhere, in this case directly from asyncStorage
      const token = getToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else if (tokenInit) {
        config.headers.Authorization = `Bearer ${tokenInit}`;
      }

      return config;
    });

    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        // Do something with response error
        if (
          error.response.status === 422 ||
          error.response.status === 401 ||
          error.response.statusText === "UNAUTHORIZED"
        ) {
          localStorage.removeItem("access_token");
          window.location.reload();
          history.push("/login");
        }

        return Promise.reject(error);
      }
    );

    return axios;
  }, [tokenInit, history]);

  return (
    <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
  );
}
